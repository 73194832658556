import React from 'react';

import { Helmet } from 'react-helmet';

//! Components
import Layout from '../components/layout';
import LandingBannerWrapper from '../components/LandingBannerWrapper';
import CTA from '../components/CTA';

//! CSS
import '../styles/style.css';
import '../styles/new.css';
import '../styles/revamp.css';

//~ Images
import GoLiveInMins from '../img/Features-Page-01@3x.png';
import HighAccuracy from '../img/high-accuracy@3x-1.png';
import NaturalConversations from '../img/natural-convo-1-2@3x.png';
import OmniChannelCapability from '../img/Features-Page-02@3x.png';
import FallOver from '../img/fall-over-1-2@3x.png';
import ConversationWorkflow from '../img/Features-Page-03@3x-1.png';
import Analytics from '../img/Features-Page-04@3x.png';
import SecureScalable from '../img/secure-scalable-illustration@1x.svg';
import HeadTags from '../components/HeadTags';

const IndexPage = () => (
  <Layout>
     <HeadTags
        title={`ChatBots | Customer Service Conversational AI Chatbots `}
        content={{
          type: 'website',
          description:
            'Automate your customer support and engagement with Conversational AI chatbots, used by leading banks, insurance companies and fintechs globally. No technical skills required.'
        }}/>
    <LandingBannerWrapper
      title={'Build a QnA virtual assistant in 10 mins'}
      page={'features'}
    />

    <div id="minimal-variants" className="features-page business-benefit-wrapper-outer">
      <div className="business-benefit-wrapper">
        <div className="business-benefit image-right">
          <div className="image">
            <img
              alt="Go Live In Minutes"
              className="benefits-long-image"
              src={GoLiveInMins}
            />
          </div>
          <div className="business-benefit-text">
            <h2 className="benefit-title">
              Go live in minutes, no painful data curation
            </h2>
            <p className="benefit-title-subtext">
              Train on existing knowledge bases e.g faq's and go live faster.
            </p>
            <h4>Minimal variants required</h4>
            <p>
              Minimal additional training data required, CognitiveQnA works with
              high accuracy on standard faq’s with a single question and answer
              pair.
            </p>
            <h4>Automatic data annotation</h4>
            <p>
              No manual annotation of data, CognitiveQnA uses cutting edge
              algorithms to automatically annotate data, which can be
              fine-tuned.
            </p>
            <h4>Automatic variant generation</h4>
            <p>
              Generate synthetic variants automatically to further improve
              accuracy using CognitiveQnA’s powerful paraphrasing algorithms.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="high-accuracy" className="features-page business-benefit-wrapper-outer">
      <div className="business-benefit-wrapper">
        <div className="business-benefit ">
          <div className="image">
            <img
              alt="High Accuracy"
              className="benefits-tall-image"
              src={HighAccuracy}
            />
          </div>
          <div className="business-benefit-text">
            <h2 className="benefit-title">High accuracy</h2>
            <p className="benefit-title-subtext">
              Leverage the power of cutting edge deep learning algorithms.
            </p>
            <h4>Paraphrasing</h4>
            <p>
              Powerful paraphrasing capability which can generate logical
              variants automatically thus reducing the need for question
              variants.
            </p>
            <h4>Deep learning domain models</h4>
            <p>
              Pre-bundled domain models boost the paraphrasing algorithms
              leading to greater breadth of coverage and increasing accuracy.
            </p>
            <h4>Bidirectional spell checkers &amp; acronyms</h4>
            <p>
              Bidirectional contextual spell checking algorithms coupled with
              acronyms dictionary which clean up user input leading to higher
              accuracy.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="natural-conversations" className="features-page business-benefit-wrapper-outer">
      <div className="business-benefit-wrapper">
        <div
          
          className="business-benefit image-right"
        >
          <div className="image">
            <img
              alt="Natural Conversations"
              className="benefits-tall-image"
              src={NaturalConversations}
            />
          </div>
          <div className="business-benefit-text">
            <h2 className="benefit-title">Natural conversations</h2>
            <p className="benefit-title-subtext">
              Human like conversations capability with context and
              disambiguation
            </p>
            <h4>Conversation context</h4>
            <p>
              Conversational memory retention capability which enables follow on
              human like contextual conversations.
            </p>
            <h4>Domain ontology &amp; disambiguation</h4>
            <p>
              Pre-bundled Domain Ontology which enables disambiguation
              capability for ambiguous queries with guided user support for
              clarification before giving a response.
            </p>
            <h4>Predictive FAQ’s</h4>
            <p>
              Predictive typing capability with ranking which enables users to
              quickly narrow down on their exact query.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div id="omni-channel-capability" className="features-page business-benefit-wrapper-outer">
      <div className="business-benefit-wrapper">
        <div className="business-benefit ">
          <div className="image">
            <img
              alt="OmniChannel Capability"
              className="benefits-long-image"
              src={OmniChannelCapability}
            />
          </div>
          <div className="business-benefit-text">
            <h2 className="benefit-title">OmniChannel capability</h2>
            <p className="benefit-title-subtext">
              Service customers on their preferred channel on voice and text
            </p>
            <h4>Multi channel text support</h4>
            <p>
              Out of the box support for social messaging channels, web and
              mobile via easy to bundle SDK’s.
            </p>
            <h4>Multi channel voice support</h4>
            <p>
              Out of the box support for voice across IOT (Alexa , Google Home),
              mobile, web and IVR (Twilio).
            </p>
            <h4>Channel specific responses</h4>
            <p>
              Support for channel specific responses to ensure best user
              experience.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="features-page business-benefit-wrapper-outer">
      <div className="business-benefit-wrapper">
        <div
          id="failover-to-human-agents-human-ai-partnership"
          className="business-benefit image-right"
        >
          <div className="image">
            <img
              alt="Fall Over"
              className="benefits-tall-image"
              src={FallOver}
            />
          </div>
          <div className="business-benefit-text">
            <h2 className="benefit-title">Failover to human agents</h2>
            <p className="benefit-title-subtext">
              Graceful in channel failover to human agents in case of failure
            </p>
            <h4>Failover to human agents</h4>
            <p>
              Graceful failover to human agents in case of unaddressable queries
              on text and voice (IVR).
            </p>
            <h4>Customer support integration</h4>
            <p>
              Prebuilt integrations to popular customer service platforms like
              LiveChat , Zendesk*, Freshdesk*, Liveperson*.
            </p>
            <h4>Supervised self learning</h4>
            <p>
              Train AI models based on customer and agent interactions via the
              admin console to improve accuracy.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="features-page business-benefit-wrapper-outer">
      <div className="business-benefit-wrapper">
        <div id="conversational-workflows" className="business-benefit ">
          <div className="image">
            <img
              alt="Conversation Workflow"
              className="benefits-long-image"
              src={ConversationWorkflow}
            />
          </div>
          <div className="business-benefit-text">
            <h2 className="benefit-title">Conversational workflows</h2>
            <p className="benefit-title-subtext">
              Craft guided conversational journeys via a web designer
            </p>
            <h4>Conversation designer</h4>
            <p>
              Intuitive feature rich drag and drop conversational workflow
              designer to orchestrate simple and complex journeys with ease.
            </p>
            <h4>Developer friendly</h4>
            <p>
              Support for scripting, web hooks, debuggers which enables building
              advanced conversational journey.
            </p>
            <h4>Collaborative, low code</h4>
            <p>
              Collaborate in real time with business users and team members to
              rapidly prototype and develop conversational use cases.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="features-page business-benefit-wrapper-outer">
      <div className="business-benefit-wrapper">
        <div id="analytics" className="business-benefit image-right">
          <div className="image">
            <img
              alt="Analytics"
              className="benefits-long-image"
              src={Analytics}
            />
          </div>
          <div className="business-benefit-text">
            <h2 className="benefit-title">Analytics</h2>
            <p className="benefit-title-subtext">
              Analyse and measure the performance of virtual bots.
            </p>
            <h4>Measure</h4>
            <p>
              Measure virtual bot metrics by users, sessions, conversations
              across channels.
            </p>
            <h4>Analyse</h4>
            <p>
              Analyse conversation journeys via powerful visualisation tools to
              find out popular functionalities and user abandonment rate.
            </p>
            <h4>Optimizie</h4>
            <p>
              Improve virtual bot performance via conversation reports to
              identify failure rates and retain models.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="features-page business-benefit-wrapper-outer">
      <div className="business-benefit-wrapper">
        <div id="secure-scalable-infrastructure" className="business-benefit ">
          <div className="image">
            <img
              alt="Secure & Scalable"
              className="benefits-square-image"
              src={SecureScalable}
            />
          </div>
          <div className="business-benefit-text">
            <h2 className="benefit-title">
              Secure &amp; scalable infrastructure
            </h2>
            <p className="benefit-title-subtext">
              On demand, scalable, secure infrastructure on AWS
            </p>
            <h4>Scalable infrastructure</h4>
            <p>
              Scalable, on demand, autoscale infrastructure hosted on proven AWS
              platform.
            </p>
            <h4>Banking grade security</h4>
            <p>
              Multi tier, banking-grade security, compliance with industry
              standard best practices. AWS certified.
            </p>
            <h4>Data privacy</h4>
            <p>
              Industry standard data privacy practices with no sharing of user
              data and compliance to applicable privacy laws.
            </p>
          </div>
        </div>
      </div>
    </div>

    <CTA />
  </Layout>
);

export default IndexPage;
