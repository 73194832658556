import React from 'react';

//! Images
import LandingBannerImage from '../img/groupmobile_1.svg';

const LandingBannerWrapper = props => {
  return (
    <div className="landing-banner-wrapper">
      <header
        className={`landing-banner ${
          props.page === 'features' ? 'features-page' : ''
        }`}
      >
        <div className="landing-banner-text">
          <h1 dangerouslySetInnerHTML={{ __html: props.title }}></h1>
          <p>{props.subtitle}</p>
          <div className="landing-demo-button get_demo">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://developer.triniti.ai/register"
            >
              Signup for free
            </a>
          </div>
        </div>
        {props.image && (
          <div className="landing-banner-image">
            <img alt="Page Banner" src={LandingBannerImage} />
          </div>
        )}
      </header>
    </div>
  );
};

export default LandingBannerWrapper;
